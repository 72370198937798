$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    $('.accHandler').accordion({openFirst:true});

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        type: 'image'
    });

    var lastScrollTop = 0;
    var checkFor = $(".navi-main").offset().top; // height to reach to add class

    // Handle navigation state
    function fixedNavbarHandler() {
        var nav             = $(".navi-main"), // navigation-elem to add class to
            backToTop       = $(".backToTop"), // backToTop-btn
            navClassToAdd   = "fixed",
            BTTClassToAdd   = "moveUp";

        if( $(document).scrollTop() > checkFor ) {
            nav.addClass( navClassToAdd );
            $('body').addClass('phoneDocked')
        } else {
            nav.removeClass( navClassToAdd );
            $('body').removeClass('phoneDocked')
        }

        if( backToTop.offset().top + 100 > $(document).height() ) {
            backToTop.addClass( BTTClassToAdd );
        } else {
            backToTop.removeClass( BTTClassToAdd );
        }
    }

    var timedEvent;

    $(window).scroll(function() {
    	if(window.innerWidth >= 992) {
    		fixedNavbarHandler();

    		var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    		   if (st > lastScrollTop){
    		        if($("body").hasClass("index")) {
    		            if($("body").scrollTop() < 100) {
    		                $("html, body").bind("scroll mousedown DOMMouseScroll mousewheel keyup", function(){
    		                    $('html, body').stop();
    		                });

    		                $("html, body").animate({
    		                    'scrollTop': $("main").offset().top
    		                }, 400);
    		            }
    		        }
    		   }
		   lastScrollTop = st;
    	}
    });

    fixedNavbarHandler();

    $.get('/_templates/_oeffnungszeiten.php', function(data) {
        var toLook = $(data).find('ul.times');
        if(toLook !== undefined && toLook.length > 0) {
            $("#navOpening, #footerOpening").replaceWith(toLook);
        }
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC. 
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    if ( breakpoint['medium'] ) {
        $maps = $('[src*="google.com/maps"]').css({
            "pointer-events": "none"
        });

        $("footer.footer .btn").on('click', function(e) {
            e.preventDefault();
            $(".quick-info").addClass("mapVisible");
        });

        $maps.parent(':not(#googlemaps-address)').on('click mouseleave', function(e){
            $bool = ( e.type === 'click' ) ? 'auto' : 'none';

            if(e.type === 'mouseleave') {
                $(".quick-info").removeClass("mapVisible");

            }

            $maps.css({
                "pointer-events": $bool
            });
        });
    }

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');
});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/